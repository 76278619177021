<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import CopyableText from "@/components/CopyableText";
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import CopyableInput from "@/components/CopyableInput";
import {BucketType, Game} from "@/enums";

export default {
  props: ['server_id', 'options'],
  components: {
    CopyableInput,
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    // eslint-disable-next-line vue/no-unused-components
    HalfCircleSpinner,
    Multiselect
  },
  computed: {
    ...mapGetters([
      'getTeams'
    ]),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    supportsQP() {
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsRS() {
      let games = [];
      return games.includes(this.options.game);
    },
    supportsWL() {
      let games = [Game.DAYZ, Game.ARMA3, Game.ARMA2];
      return games.includes(this.options.game);
    },
    async resetGameStats() {
      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('server.view.settings.dangerzone.resetgame.confirm.text'),
        title: this.$t('server.view.settings.dangerzone.resetgame.confirm.title'),
        showCancelButton: true,
        confirmButtonText: this.$t('server.view.settings.dangerzone.resetgame.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) return;

      this.$emit('actionEnableFullpage', this.$t('server.view.settings.dangerzone.resetgame.in_progress'), 'danger', true);
      this.$emit('actionToggleFullpage');

      await new Promise(resolve => setTimeout(resolve, 1000));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/reset-game`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('server.view.settings.dangerzone.completed.text'),
            title: this.$t('server.view.settings.dangerzone.completed.title')
          });
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.$emit('actionToggleFullpage');
    },
    async resetAllStats() {
      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('server.view.settings.dangerzone.resetall.confirm.text'),
        title: this.$t('server.view.settings.dangerzone.resetall.confirm.title'),
        showCancelButton: true,
        confirmButtonText: this.$t('server.view.settings.dangerzone.resetall.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) return;

      this.$emit('actionEnableFullpage', this.$t('server.view.settings.dangerzone.resetall.in_progress'), 'danger', true);
      this.$emit('actionToggleFullpage');

      await new Promise(resolve => setTimeout(resolve, 1000));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/reset-all`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('server.view.settings.dangerzone.completed.text'),
            title: this.$t('server.view.settings.dangerzone.completed.title')
          });
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.$emit('actionToggleFullpage');
    },
    async wipePlayerDB() {
      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('server.view.settings.dangerzone.dbwipe.confirm.text'),
        title: this.$t('server.view.settings.dangerzone.dbwipe.confirm.title'),
        showCancelButton: true,
        confirmButtonText: this.$t('server.view.settings.dangerzone.dbwipe.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) return;

      this.$emit('actionEnableFullpage', this.$t('server.view.settings.dangerzone.dbwipe.in_progress'), 'danger', true);
      this.$emit('actionToggleFullpage');

      await new Promise(resolve => setTimeout(resolve, 1000));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/clear-db`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('server.view.settings.dangerzone.completed.text'),
            title: this.$t('server.view.settings.dangerzone.completed.title')
          });
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.$emit('actionToggleFullpage');
    },
    async deleteServer() {
      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('server.view.settings.dangerzone.delete.confirm.text'),
        title: this.$t('server.view.settings.dangerzone.delete.confirm.title'),
        showCancelButton: true,
        confirmButtonText: this.$t('server.view.settings.dangerzone.delete.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) return;

      this.$emit('actionEnableFullpage', this.$t('server.view.settings.dangerzone.delete.in_progress'), 'danger', true);
      this.$emit('actionToggleFullpage');

      await new Promise(resolve => setTimeout(resolve, 1000));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/delete`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          // Wait for acknowledge from control plane
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.$emit('actionToggleFullpage');
    },
    async regenerateApiKey() {
      let result = await this.$swal({
        icon: 'warning',
        title: this.$t('server.view.settings.api.new.confirm'),
        showCancelButton: true,
        confirmButtonText: this.$t('server.view.settings.api.new.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) return;

      this.$emit('actionEnableFullpage', this.$t('server.view.settings.api.new.inprogress'), 'default', true);
      this.$emit('actionToggleFullpage');

      await new Promise(resolve => setTimeout(resolve, 5000));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/apiKey`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.apiKey = data.api_key;
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.$emit('actionToggleFullpage');
    },
    async wipeServer() {
      this.wipeInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/wipe`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.settings.dangerzone.wipe.success.title')
          });
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.wipeInProgress = false;
    },
    async onToggleOption(setting, event) {
      this.transmission[setting] = true;
      setTimeout(() => {
        this.updateSetting(setting, event.value);
      }, 750);
    },
    async updateSetting(setting, value) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          setting: setting,
          value: value
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/setting`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          // Ok
        } else {
          if(response.status === 429) {
            this.settings[setting] = !value;
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.settings[setting] = !value;
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.transmission[setting] = false;
    },
    getSettings: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/settings`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.settings = data.settings;
          this.buckets = data.buckets;
          Object.keys(this.settings).forEach(k => {
            this.transmission[k] = false;
          });
          this.assignedTeam = data.team;
          this.apiKey = data.api_key;
          this.serverUuid = data.uuid;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async assignToTeam() {
      if(!this.selectedTeam) return;
      this.teamInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          team_uuid: this.selectedTeam.uuid
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/team`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          if(data.team !== null) {
            this.assignedTeam = data.team;
            this.selectedTeam = null;
            this.$swal({
              icon: 'success',
              title: this.$t('server.view.settings.team.add.success')
            });
          } else {
            this.assignedTeam = null;
            this.$swal({
              icon: 'success',
              title: this.$t('server.view.settings.team.remove.success')
            });
          }
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.teamInProgress = false;
    },
    async removeFromTeam() {
      this.teamInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          team_uuid: null
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/team`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          if(data.team !== null) {
            this.assignedTeam = data.team;
            this.$swal({
              icon: 'success',
              title: this.$t('server.view.settings.team.add.success')
            });
          } else {
            this.assignedTeam = null;
            this.$swal({
              icon: 'success',
              title: this.$t('server.view.settings.team.remove.success')
            });
          }
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.teamInProgress = false
    },
    async createBucket(bucketType) {
      this.teamInProgress = true;
      try {
        let result = await this.$swal({
          input: 'text',
          text: this.$t('server.view.settings.bucket.create.name'),
          title: this.$t('server.view.settings.bucket.create.title'),
          showCancelButton: true,
          confirmButtonText: this.$t('server.view.settings.bucket.create.button'),
          confirmButtonColor: '#f46a6a',
          cancelButtonColor: '#c3cbe4',
          showLoaderOnConfirm: true,
          preConfirm: async (name) => {
            name = name.trim();
            let acsrf_token = await get_acsrf_token();
            let payload = {
              acsrf_token: acsrf_token,
              bucket_type: bucketType,
              name: name
            };
            let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/bucket`, {
              method: 'PUT',
              body: JSON.stringify(payload),
              credentials: 'include'
            });
            if(response.ok) {
              return response.json();
            } else {
              if(response.status === 429) {
                this.$swal({
                  icon: 'warning',
                  text: this.$t('error.server.ratelimit.message'),
                  title: this.$t('error.server.ratelimit.title')
                });
              } else
                this.$swal.showValidationMessage(this.$t('server.view.settings.bucket.create.error'));
            }
          }
        }).then((result) => {
          if(result.isConfirmed) {
            this.getSettings();
            this.$swal({
              icon: 'success',
              title: this.$t('server.view.settings.bucket.create.success')
            });
          }
        });
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.teamInProgress = false;
    },
    async removeBucket(bucketType) {
      this.teamInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          bucket_type: bucketType
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/bucket`, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.settings.bucket.remove.success')
          });
          await this.getSettings();
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.teamInProgress = false;
    },
    async assignBucket(bucketType, bucketUuid) {
      this.teamInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          bucket_type: bucketType,
          bucket_uuid: bucketUuid
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/bucket`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.settings.bucket.add.success')
          });
          await this.getSettings();
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.teamInProgress = false;
    },

    async assignBanlist() {
      this.teamInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_uuid: this.selectedBucket.banlist.uuid,
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/banlist`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.settings.banlist.add.success')
          });
          await this.getSettings();
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.teamInProgress = false;
    },
    async removeBanlist() {
      this.teamInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/banlist`, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.settings.banlist.remove.success')
          });
          await this.getSettings();
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.teamInProgress = false;
    },
    async followBanlist() {
      if(this.shareCode.length !== 36 || (this.shareCode.match(/-/g) || []).length !== 4) {
        this.$refs.shareCode.$el.classList.add('is-invalid');
        return;
      } else {
        this.$refs.shareCode.$el.classList.remove('is-invalid');
        this.$refs.shareCode.$el.classList.add('is-valid');
        setTimeout(() => {
          this.$refs.shareCode.$el.classList.remove('is-valid');
        }, 750);
      }
      this.teamInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_uuid: this.shareCode
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/banlists`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.shareCode = null;
          this.getSettings();
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.settings.banlists.add.success')
          });
        } else {
          //if(response.)
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else if(response.status === 400) {
            let data = await response.json();
            if(data.error === 'primary') {
              this.shareCode = null;
              this.$swal({
                icon: 'warning',
                text: this.$t('server.view.settings.banlists.add.error.primary')
              });
            } else if(data.error === 'followed') {
              this.shareCode = null;
              this.$swal({
                icon: 'warning',
                text: this.$t('server.view.settings.banlists.add.error.followed')
              });
            } else
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('banmanager.view.settings.follows.add.error.title')
        });
      }
      this.teamInProgress = false;
    },
    async unfollowBanlist(follow) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: follow.id
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/banlists`, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.getSettings();
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.settings.banlists.remove.success')
          });
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    },
  },
  mounted() {
    this.getSettings();
    this.getTeams().forEach((team) => {
      this.availableTeams.push({
        name: team.name,
        uuid: team.uuid
      })
    })
  },
  data() {
    return {
      BucketType:BucketType,
      ready: false,
      error: false,
      inProgress: false,
      wipeInProgress: false,
      shareCode: null,
      settings: {

      },
      selectedBucket: {
        whitelist: null,
        notes: null,
        queuepriority: null,
        reservedslots: null,
        namefilter: null,
        chatfilter: null
      },
      buckets: {},
      apiKey: null,
      serverUuid: null,
      transmission: {
        public: null,
        evidence_public: null,
        allow_followers: null,
        keep_inactive: null
      },
      availableTeams: [],
      selectedTeam: null,
      assignedTeam: null,
      teamInProgress: false
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-9">
          <div>
            <div role="tablist">
              <!-- Primary ban list -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.banlist class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.banlist.title') }}

                      <i class="far fa-exclamation text-info font-size-16 ml-1" />
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="banlist" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('server.view.settings.banlist.description') }}
                          </h5>
                        </div>
                      </div>
                      <template v-if="settings.banlist === null">
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12">
                            <multiselect
                                v-model="selectedBucket.banlist"
                                label="title"
                                track-by="title"
                                :options="buckets.banlist"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div>
                                  <span class="option__title align-middle h4">
                                    {{ props.option.name }}
                                  </span>
                                  <div class="option__desc d-inline align-middle">
                                    {{props.option.uuid}}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                    <span class="option__title align-middle h4">
                                      {{ props.option.name }}
                                    </span>
                                    <span class="option__small align-middle ml-2 h6">
                                      {{ props.option.uuid }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-primary btn-block" v-on:click="assignBanlist()" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.banlist.add.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12 text-center">
                            <span class="option__title align-middle h4">
                              {{ settings.banlist.name }}
                            </span>
                            <div class="option__desc">
                              {{ settings.banlist.uuid }}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-warning text-black btn-block" v-on:click="removeBanlist()" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.banlist.remove.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Bucket: Whitelist -->
              <b-card no-body class="settings-collapse hard-border" v-if="supportsWL()">
                <a v-b-toggle.bucketWhitelist class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.whitelist.title') }}

                      <i class="far fa-info-circle text-primary font-size-16 ml-1" v-b-tooltip.hover :title="$t('server.view.settings.bucket.tooltip')" />
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="bucketWhitelist" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('server.view.settings.whitelist.description') }}
                          </h5>
                        </div>
                      </div>
                      <template v-if="settings.whitelist === null">
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12">
                            <multiselect
                                v-model="selectedBucket.whitelist"
                                label="title"
                                track-by="title"
                                :options="buckets.whitelist"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div>
                                  <span class="option__title align-middle h4">
                                    {{ props.option.name }}
                                  </span>
                                  <div class="option__desc d-inline align-middle">
                                    {{props.option.uuid}}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                    <span class="option__title align-middle h4">
                                      {{ props.option.name }}
                                    </span>
                                    <span class="option__small align-middle ml-2 h6">
                                      {{ props.option.uuid }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-primary btn-block" v-on:click="assignBucket(BucketType.WHITELIST, selectedBucket.whitelist.uuid)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.add.button') }}
                            </button>
                          </div>
                        </div>
                        <div class="mt-1 text-muted text-uppercase text-center">- or -</div>
                        <div class="row mt-1 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-info btn-block" v-on:click="createBucket(BucketType.WHITELIST)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.create.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12 text-center">
                            <span class="option__title align-middle h4">
                              {{ settings.whitelist.name }}
                            </span>
                            <div class="option__desc">
                              {{ settings.whitelist.uuid }}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-warning text-black btn-block" v-on:click="removeBucket(BucketType.WHITELIST)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.remove.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Bucket: Queue Priority -->
              <b-card no-body class="settings-collapse hard-border" v-if="supportsQP()">
                <a v-b-toggle.bucketQueuePriority class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.queuepriority.title') }}

                      <i class="far fa-info-circle text-primary font-size-16 ml-1" v-b-tooltip.hover :title="$t('server.view.settings.bucket.tooltip')" />
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="bucketQueuePriority" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-12">
                          <h4>
                          <div class="alert alert-info text-center">
                            {{ $t('server.view.settings.queuepriority.disclaimer') }}
                          </div>
                          </h4>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('server.view.settings.queuepriority.description') }}
                          </h5>
                        </div>
                      </div>
                      <template v-if="settings.queuepriority === null">
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12">
                            <multiselect
                                v-model="selectedBucket.queuepriority"
                                label="title"
                                track-by="title"
                                :options="buckets.queue_priority"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div>
                                  <span class="option__title align-middle h4">
                                    {{ props.option.name }}
                                  </span>
                                  <div class="option__desc d-inline align-middle">
                                    {{props.option.uuid}}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                    <span class="option__title align-middle h4">
                                      {{ props.option.name }}
                                    </span>
                                    <span class="option__small align-middle ml-2 h6">
                                      {{ props.option.uuid }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-primary btn-block" v-on:click="assignBucket(BucketType.QUEUE_PRIORITY, selectedBucket.queuepriority.uuid)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.add.button') }}
                            </button>
                          </div>
                        </div>
                        <div class="mt-1 text-muted text-uppercase text-center">- or -</div>
                        <div class="row mt-1 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-info btn-block" v-on:click="createBucket(BucketType.QUEUE_PRIORITY)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.create.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12 text-center">
                            <span class="option__title align-middle h4">
                              {{ settings.queuepriority.name }}
                            </span>
                            <div class="option__desc">
                              {{ settings.queuepriority.uuid }}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-warning text-black btn-block" v-on:click="removeBucket(BucketType.QUEUE_PRIORITY)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.remove.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Bucket: Reserved Slots -->
              <b-card no-body class="settings-collapse hard-border" v-if="supportsRS()">
                <a v-b-toggle.bucketReservedSlots class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.reservedslots.title') }}

                      <i class="far fa-info-circle text-primary font-size-16 ml-1" v-b-tooltip.hover :title="$t('server.view.settings.bucket.tooltip')" />
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="bucketReservedSlots" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('server.view.settings.reservedslots.description') }}
                          </h5>
                        </div>
                      </div>
                      <template v-if="settings.reservedslots === null">
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12">
                            <multiselect
                                v-model="selectedBucket.reservedslots"
                                label="title"
                                track-by="title"
                                :options="buckets.reservedslots"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div>
                                  <span class="option__title align-middle h4">
                                    {{ props.option.name }}
                                  </span>
                                  <div class="option__desc d-inline align-middle">
                                    {{props.option.uuid}}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                    <span class="option__title align-middle h4">
                                      {{ props.option.name }}
                                    </span>
                                    <span class="option__small align-middle ml-2 h6">
                                      {{ props.option.uuid }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-primary btn-block" v-on:click="assignBucket(BucketType.RESERVED_SLOT, selectedBucket.reservedslots.uuid)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.add.button') }}
                            </button>
                          </div>
                        </div>
                        <div class="mt-1 text-muted text-uppercase text-center">- or -</div>
                        <div class="row mt-1 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-info btn-block" v-on:click="createBucket(BucketType.RESERVED_SLOT)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.create.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12 text-center">
                            <span class="option__title align-middle h4">
                              {{ settings.reservedslots.name }}
                            </span>
                            <div class="option__desc">
                              {{ settings.reservedslots.uuid }}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-warning text-black btn-block" v-on:click="removeBucket(BucketType.RESERVED_SLOT)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.remove.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Bucket: Notes -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.bucketNotes class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.notes.title') }}

                      <i class="far fa-info-circle text-primary font-size-16 ml-1" v-b-tooltip.hover :title="$t('server.view.settings.bucket.tooltip')" />
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="bucketNotes" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('server.view.settings.notes.description') }}
                          </h5>
                        </div>
                      </div>
                      <template v-if="settings.notes === null">
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12">
                            <multiselect
                                v-model="selectedBucket.notes"
                                label="title"
                                track-by="title"
                                :options="buckets.notes"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div>
                                  <span class="option__title align-middle h4">
                                    {{ props.option.name }}
                                  </span>
                                  <div class="option__desc d-inline align-middle">
                                    {{props.option.uuid}}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                    <span class="option__title align-middle h4">
                                      {{ props.option.name }}
                                    </span>
                                    <span class="option__small align-middle ml-2 h6">
                                      {{ props.option.uuid }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-primary btn-block" v-on:click="assignBucket(BucketType.NOTES, selectedBucket.notes.uuid)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.add.button') }}
                            </button>
                          </div>
                        </div>
                        <div class="mt-1 text-muted text-uppercase text-center">- or -</div>
                        <div class="row mt-1 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-info btn-block" v-on:click="createBucket(BucketType.NOTES)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.create.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12 text-center">
                            <span class="option__title align-middle h4">
                              {{ settings.notes.name }}
                            </span>
                            <div class="option__desc">
                              {{ settings.notes.uuid }}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-warning text-black btn-block" v-on:click="removeBucket(BucketType.NOTES)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.remove.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Bucket: Chat Filter -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.bucketChatFilter class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.chatfilter.title') }}

                      <i class="far fa-info-circle text-primary font-size-16 ml-1" v-b-tooltip.hover :title="$t('server.view.settings.bucket.tooltip')" />
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="bucketChatFilter" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('server.view.settings.chatfilter.description') }}
                          </h5>
                        </div>
                      </div>
                      <template v-if="settings.chatfilter === null">
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12">
                            <multiselect
                                v-model="selectedBucket.chatfilter"
                                label="title"
                                track-by="title"
                                :options="buckets.chatfilter"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div>
                                  <span class="option__title align-middle h4">
                                    {{ props.option.name }}
                                  </span>
                                  <div class="option__desc d-inline align-middle">
                                    {{props.option.uuid}}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                    <span class="option__title align-middle h4">
                                      {{ props.option.name }}
                                    </span>
                                    <span class="option__small align-middle ml-2 h6">
                                      {{ props.option.uuid }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-primary btn-block" v-on:click="assignBucket(BucketType.CHAT_FILTER, selectedBucket.chatfilter.uuid)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.add.button') }}
                            </button>
                          </div>
                        </div>
                        <div class="mt-1 text-muted text-uppercase text-center">- or -</div>
                        <div class="row mt-1 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-info btn-block" v-on:click="createBucket(BucketType.CHAT_FILTER)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.create.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12 text-center">
                            <span class="option__title align-middle h4">
                              {{ settings.chatfilter.name }}
                            </span>
                            <div class="option__desc">
                              {{ settings.chatfilter.uuid }}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-warning text-black btn-block" v-on:click="removeBucket(BucketType.CHAT_FILTER)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.remove.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Bucket: Name Filter -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.bucketNameFilter class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.namefilter.title') }}

                      <i class="far fa-info-circle text-primary font-size-16 ml-1" v-b-tooltip.hover :title="$t('server.view.settings.bucket.tooltip')" />
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="bucketNameFilter" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('server.view.settings.namefilter.description') }}
                          </h5>
                        </div>
                      </div>
                      <template v-if="settings.namefilter === null">
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12">
                            <multiselect
                                v-model="selectedBucket.namefilter"
                                label="title"
                                track-by="title"
                                :options="buckets.namefilter"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div>
                                  <span class="option__title align-middle h4">
                                    {{ props.option.name }}
                                  </span>
                                  <div class="option__desc d-inline align-middle">
                                    {{props.option.uuid}}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                    <span class="option__title align-middle h4">
                                      {{ props.option.name }}
                                    </span>
                                    <span class="option__small align-middle ml-2 h6">
                                      {{ props.option.uuid }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-primary btn-block" v-on:click="assignBucket(BucketType.NAME_FILTER, selectedBucket.namefilter.uuid)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.add.button') }}
                            </button>
                          </div>
                        </div>
                        <div class="mt-1 text-muted text-uppercase text-center">- or -</div>
                        <div class="row mt-1 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-info btn-block" v-on:click="createBucket(BucketType.NAME_FILTER)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.create.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12 text-center">
                            <span class="option__title align-middle h4">
                              {{ settings.namefilter.name }}
                            </span>
                            <div class="option__desc">
                              {{ settings.namefilter.uuid }}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-warning text-black btn-block" v-on:click="removeBucket(BucketType.NAME_FILTER)" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.bucket.remove.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- 3rd party ban lists -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.follow class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.banlists.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="follow" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('server.view.settings.banlists.description') }}
                          </h5>
                        </div>
                      </div>
                      <!-- Share code -->
                      <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-12">
                          <b-input-group size="lg">
                            <b-form-input ref="shareCode" v-model="shareCode" :placeholder="$t('server.view.settings.banlists.add.label')" />
                            <b-input-group-append>
                              <b-button variant="primary" v-on:click="followBanlist()" :class="{'disabled': teamInProgress}">
                                <half-circle-spinner
                                    v-if="teamInProgress"
                                    :animation-duration="1200"
                                    :size="16"
                                    class="align-middle d-inline-block"
                                />

                                {{ $t('server.view.settings.banlists.add.button') }}
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </div>
                      <!-- List -->

                      <div class="row mt-2 justify-content-center" v-if="settings.banlists.length">
                        <div class="col-lg-5 col-sm-12">
                          <h5>{{ $t('server.view.settings.banlists.list') }}</h5>
                          <div class="table-responsive">
                            <table class="table table-nowrap table-centered table-hover mb-0 align-middle">
                              <tbody>
                              <tr v-for="follow in settings.banlists" :key="follow.id">
                                <td>
                                  <h5 class="font-size-14 mb-1">
                                    {{ follow.name }}
                                  </h5>
                                  <small>
                                    ID:
                                    <CopyableText :text="follow.id">{{follow.id}}</CopyableText>
                                  </small>
                                </td>
                                <td>
                                  <div class="text-center">
                                    <button class="btn btn-warning text-black" v-on:click="unfollowBanlist(follow)">
                                      {{ $t('server.view.settings.banlists.remove.button') }}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col text-center">
                          <span class="text-muted">
                            {{ $t('server.view.settings.banlists.none') }}
                          </span>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Team -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.team class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.team.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="team" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('server.view.settings.team.description') }}
                          </h5>
                        </div>
                      </div>
                      <template v-if="assignedTeam === null">
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12">
                            <multiselect
                                v-model="selectedTeam"
                                label="title"
                                track-by="title"
                                :options="availableTeams"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div>
                                  <span class="option__title align-middle h4">
                                    {{ props.option.name }}
                                  </span>
                                  <div class="option__desc d-inline align-middle">
                                    {{props.option.uuid}}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                    <span class="option__title align-middle h4">
                                      {{ props.option.name }}
                                    </span>
                                    <span class="option__small align-middle ml-2 h6">
                                      {{ props.option.uuid }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-primary btn-block" v-on:click="assignToTeam()" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.team.add.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12 text-center">
                            <span class="option__title align-middle h4">
                              {{ assignedTeam.name }}
                            </span>
                            <div class="option__desc">
                              {{assignedTeam.uuid}}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-warning text-black btn-block" v-on:click="removeFromTeam()" :class="{'disabled': teamInProgress}">
                              <half-circle-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('server.view.settings.team.remove.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- API Key -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.api class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('server.view.settings.api.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="api" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-lg-12 col-sm-12">
                          <h4>
                            Server ID
                          </h4>
                          <CopyableInput size="md" class="mt-1" :text="serverUuid"/>
                        </div>
                      </div>
                      <div class="row mt-2" v-if="options.privileged">
                        <div class="col-lg-8 col-sm-12">
	                        <h4>
		                        {{ $t('server.view.settings.api.title') }}
		                        <span class="small text-muted">
                                {{ $t('server.view.settings.api.description') }}
                              </span>
	                        </h4>
	                        <CopyableInput size="md" class="mt-1" :text="apiKey" :label="$t('server.view.settings.api.title')"/>
                        </div>
                        <div class="col-lg-4 col-sm-12 mt-2">
	                        <div class="h-100 d-flex flex-column justify-content-end">
		                        <button class="btn btn-block btn-warning text-black" v-on:click="regenerateApiKey()">
			                        {{ $t('server.view.settings.api.new.button') }}
		                        </button>
	                        </div>

                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Dangerzone -->
              <b-card no-body class="settings-collapse border border-danger hard-border">
                <a v-b-toggle.dangerzone class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex text-danger">
                      {{ $t('server.view.settings.dangerzone.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="dangerzone" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row" v-if="options.permissions.administration.resetgamestats">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4 class="text-danger">
                              {{ $t('server.view.settings.dangerzone.resetgame.title') }}
                            </h4>
                            <span>
                              {{ $t('server.view.settings.dangerzone.resetgame.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <button class="btn btn-block btn-danger" v-on:click="resetGameStats()">
                              {{ $t('server.view.settings.dangerzone.resetgame.button') }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2 row" v-if="options.permissions.administration.resetstats">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4 class="text-danger">
                              {{ $t('server.view.settings.dangerzone.resetall.title') }}
                            </h4>
                            <span>
                              {{ $t('server.view.settings.dangerzone.resetall.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <button class="btn btn-block btn-danger" v-on:click="resetAllStats()">
                              {{ $t('server.view.settings.dangerzone.resetall.button') }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2 row" v-if="options.permissions.administration.cleardb">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4 class="text-danger">
                              {{ $t('server.view.settings.dangerzone.dbwipe.title') }}
                            </h4>
                            <span>
                              {{ $t('server.view.settings.dangerzone.dbwipe.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <button class="btn btn-block btn-danger" v-on:click="wipePlayerDB()">
                              {{ $t('server.view.settings.dangerzone.dbwipe.button') }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2 row" v-if="options.privileged">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4 class="text-danger">
                              {{ $t('server.view.settings.dangerzone.delete.title') }}
                            </h4>
                            <span>
                              {{ $t('server.view.settings.dangerzone.delete.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <button class="btn btn-block btn-danger" v-on:click="deleteServer()">
                              {{ $t('server.view.settings.dangerzone.delete.button') }}
                            </button>
                          </div>
                        </div>
                      </div>
	                    <div class="mt-2 row" v-if="!options.privileged && !(options.permissions.administration.cleardb || options.permissions.administration.resetstats || options.permissions.administration.resetgamestats)">
		                    <div class="col text-center">
			                    <h5 class="text-muted">{{$t('error.permissions.message')}}</h5>
			                    <h6 class="text-muted">{{$t('error.permissions.details')}}</h6>
		                    </div>
	                    </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
